@import "../../constants.less";

.tagline {
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    color: white;
    width: 40%;
    text-align: left;
    margin-left: 80px;
    z-index: 15;

    a {
        border: 1px solid white;
        border-radius: 10px;
        width: fit-content;
        padding: 10px 20px;
        pointer-events: all;
    }

    h1 {
        text-align: left !important;
    }
}

@media screen and (max-width: @header-design) {
    .tagline {
        width: 80%;
        margin-left: 10px;
    }
}
