@import "./constants.less";

@font-face {
    font-family: "Rubik";
    src: url("fonts/Rubik-Regular.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Rubik-Bold";
    src: url("fonts/Rubik-Bold.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Rubik-Italic";
    src: url("fonts/Rubik-Italic.ttf");
    font-weight: normal;
    font-style: normal;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
    font-family: Rubik;
    background-color: @cubosWhite;

    a {
        text-decoration: none;
        color: inherit;
    }

    .content {
        width: 100%;
        max-width: 75vw;
        z-index: 10;
    }

    .content-small {
        width: 100%;
        max-width: 60vw;
        z-index: 10;
    }

    .content-synopsis {
        width: 100%;
        max-width: 800px;
        z-index: 10;
    }

    .text-grey {
        color: @cubosGrey-text;
    }
    .text-yellow {
        color: @cubosYellow;
    }
    .text-blue {
        color: @cubosBlue;
    }
    .text-pink {
        color: @cubosPink;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        text-align: center;
        font-family: Rubik-Bold;
        letter-spacing: -1px;
    }

    h1 {
        font-size: 54px;
    }

    .background-cube {
        position: absolute;
        width: 32px;
        height: 32px;
        background-color: @cubosWhite;
        border-radius: 6px;

        &.small {
            width: 24px;
            height: 24px;
        }

        &.large {
            width: 48px;
            height: 48px;
        }

        &.mobile {
            display: none;
        }
    }

    .background-reverted {
        .background-cube {
            background-color: @cubosLightGrey;
        }
    }

    img.hero-image {
        width: 100%;
        object-fit: contain;
        min-height: 300px;

        &.sneak-peek {
            margin: 50px 0;
        }

        &.desktop {
            display: initial;
        }

        &.mobile {
            display: none;
        }
    }

    .synopsis {
        position: relative;
        margin: 30px 0;
        padding: 50px 0;
        width: 100vw;
        min-height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
        background-color: @cubosLightGrey;
        color: @cubosGrey-text;

        p {
            font-family: Rubik-Bold;
            font-size: 22px;
            padding: 0 16px;
        }

        h1 {
            margin-bottom: 60px;
        }
    }
}

@media screen and (max-width: 1200px) {
    #root {
        .content-small,
        .content {
            max-width: 90vw;
        }
    }
}

@media screen and (max-width: 1030px) {
    #root {
        img.hero-image {
            min-height: 120px;
        }

        .background-cube {
            &.no-mobile {
                display: none;
            }

            &.mobile {
                display: initial;
            }
        }
    }
}

@media screen and (max-width: @mobile-design) {
    #root {
        .content,
        .content-small {
            max-width: unset;
        }

        h1 {
            font-size: 34px;
        }

        img.hero-image {
            &.desktop {
                display: none;
            }

            &.mobile {
                display: initial;
            }
        }
    }
}

@media screen and (min-width: @fourK-design) {
    #root {
        img.hero-image {
            min-height: 1000px;
        }

        h1 {
            font-size: 72px;
        }
    }
}
