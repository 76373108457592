@import "../../constants.less";

.cubos-carousel {
    width: 100%;
    border-radius: 16px;
    overflow: hidden;

    ul.control-dots {
        .dot {
            height: 18px;
            width: 18px;
            border-radius: 5px;
            border: 1px solid white;
            background-color: transparent;
            transition: background-color 0.5s ease;
            opacity: 1;
            pointer-events: all;

            &.selected {
                background-color: white !important;
            }

            &:hover {
                background-color: @cubosDarkGrey;
            }
        }
    }
}

@media screen and (max-width: @header-design) {
    .cubos-carousel {
        ul.control-dots {
            .dot:hover {
                background-color: unset;
            }
        }
    }
}
