@import "../../constants.less";

.project-scope {
    display: flex;
    flex-direction: column;
    text-align: center;
    color: @cubosGrey-text;
    margin: 10px 0;

    h1 {
        margin-bottom: 60px;
    }

    .scope-content {
        margin: 30px 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;

        hr {
            border: none;
            border-left: 1px @cubosGrey-text solid;
            height: 100px;
            margin: 0 60px;
        }

        .scope-content-item {
            display: flex;
            flex-direction: column;
            text-align: center;

            img {
                max-width: 80px;
                height: 60px;
                margin: 0px auto;
                object-fit: contain;
            }

            p {
                margin: 2px 0px;
            }
        }
    }
}

@media screen and (max-width: @mobile-design) {
    .project-scope {
        .scope-content {
            flex-direction: column;
            align-items: center;

            hr {
                border: none;
                border-top: 1px @cubosGrey-text solid;

                height: 0px;
                width: 80%;
                margin: 20px 0;
            }
        }
    }
}
