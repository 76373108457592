@import "../../constants.less";

.scroll,
.with-glass {
    .glass {
        background-color: rgba(255, 255, 255, 0.85);
        box-shadow: 0px 10px 15px -5px rgba(0, 0, 0, 0.2);
        transition: box-shadow @headerShadowAnimationDuration,
            background-color @headerShadowAnimationDuration;

        &.no-shadow {
            background-color: @cubosWhite;
            box-shadow: none !important;
        }
    }

    @supports ((-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))) {
        .glass {
            background-color: rgba(255, 255, 255, 0.45);
            -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
        }
    }
}

header {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: @headerHeight;
    width: 100%;
    z-index: 1000;

    .header-shadow {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: @headerHeight;
    }

    .container {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        box-sizing: border-box;

        * {
            margin: 0;
        }

        > a {
            height: 100%;
        }

        img {
            height: 100%;
            object-fit: contain;
            margin-right: 100px;
        }

        nav {
            margin: 0 20px;
            display: flex;
            flex-direction: row;

            .nav-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                margin: 0 20px;

                img {
                    height: 20px;
                    object-fit: contain;
                    margin: 6px 0;
                }

                &:last-child {
                    img {
                        height: 18px;
                    }
                }

                p {
                    font-size: 12px;
                    font-family: Rubik-Bold;
                }
            }
        }

        .menu-btn-container {
            display: none;
        }

        .mobile-menu {
            display: none;
        }
    }
}

@media screen and (max-width: 1250px) {
    header {
        .container {
            img {
                max-width: 180px;
                margin-right: 30px;
            }
        }
    }
}

@media screen and (max-width: @header-design) {
    header {
        .container {
            justify-content: space-between;
            max-width: unset !important;

            img {
                max-width: 150px;
            }

            nav {
                display: none;
            }

            .menu-btn-container {
                box-sizing: border-box;
                display: flex;
                justify-content: flex-end;
                width: fit-content;
                height: 100%;
                padding: 16px;
                cursor: pointer;
                transition: visiblity 0.5s;

                &.menu-closed {
                    img:first-child {
                        height: 100%;
                        object-fit: contain;
                        margin: 0;
                        visibility: visible;
                    }

                    img:last-child {
                        display: none;
                        visibility: hidden;
                    }
                }

                &.menu-open {
                    img:first-child {
                        display: none;
                        visibility: hidden;
                    }

                    img:last-child {
                        height: 100%;
                        object-fit: contain;
                        margin: 0;
                        visibility: visible;
                    }
                }
            }

            .mobile-menu {
                background-color: @cubosWhite;
                display: flex;
                flex-direction: column;
                position: absolute;
                height: fit-content;
                width: 100%;
                top: @headerHeight;
                max-height: 0;
                transition: max-height 0.7s ease;
                overflow: hidden;

                &.show {
                    max-height: 520px;
                }

                .nav-item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin: 10px 0;
                    padding: 10px 0 10px 30px;

                    img {
                        width: 40px;
                        height: 30px;
                        object-fit: contain;
                        margin-right: 20px;
                    }

                    &:last-of-type {
                        img {
                            height: 25px;
                        }
                    }
                }

                hr {
                    width: 60%;
                    margin: 20px;
                }

                .social {
                    display: flex;
                    flex-direction: row;
                    padding-bottom: 20px;

                    img {
                        width: 36px;
                        height: 36px;
                        object-fit: contain;
                        margin: 0 20px;
                    }
                }
            }
        }
    }
}
