@import "../../constants.less";

#layout {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    main {
        padding: @headerHeight @desktopPaddingAside 40px @desktopPaddingAside;
    }

    #layout-content {
        min-height: calc(100vh);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

@media screen and (max-width: @mobile-design) {
    #layout {
        main {
            padding: @headerHeight @mobilePaddingAside 40px @mobilePaddingAside;
        }
    }
}
