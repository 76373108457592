@import "../../constants.less";

.cubos-character {
    width: 100%;
    margin: 16px 0;
    position: relative;
    box-sizing: content-box;
    z-index: 10;

    .character-background {
        position: absolute;
        height: 300px;
        z-index: -10;
        inset: 0;
        top: unset;
        border-radius: 20px;
        display: flex;
        align-items: center;
        padding: 20px;

        &.character-left {
            justify-content: flex-end;
        }

        &.character-right {
            justify-content: flex-start;
        }

        &.yellow {
            background-color: @cubosYellow;
        }

        &.pink {
            background-color: @cubosPink;
        }

        &.blue {
            background-color: @cubosBlue;
        }
    }

    .card {
        padding: 0 10%;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        color: white;
        z-index: 10;

        &.left {
            flex-direction: row;
        }

        &.right {
            flex-direction: row-reverse;
        }

        .text {
            display: flex;
            flex-direction: column;
            width: 40%;
            min-width: 320px;
            height: 340px;
            justify-content: center;

            img {
                max-width: 300px;
                margin: 10px 0;
            }
        }

        .character-animation > img {
            height: 450px;
            object-fit: contain;
        }
    }
}

@media screen and (max-width: 1200px) {
    .cubos-character {
        .character-background {
            height: 280px;
        }

        .card {
            .character-animation > img {
                height: 410px;
            }

            .text {
                height: 320px;
            }
        }

        .card {
            .text {
                img {
                    max-width: 250px;
                }
            }
        }
    }
}

@media screen and (max-width: 950px) {
    .cubos-character {
        .character-background {
            height: unset;
            top: 16%;
        }

        .card.left,
        .card.right {
            flex-direction: column;
            align-items: center;

            > img {
                width: 90%;
                height: unset;
                object-fit: contain;
            }

            .text {
                width: 100%;
                min-width: unset;
                padding: 20px;

                .card-content {
                    align-items: center;

                    img {
                        max-width: 90%;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: @fourK-design) {
    .cubos-character {
        .character-background {
            height: 400px;
        }

        .card {
            .character-animation > img {
                height: 580px;
            }

            .text {
                height: 440px;
            }
        }
    }

    .contact-us {
        .cubos-character .card .character-animation > img {
            margin-left: 200px !important;
        }
    }
}
