@import "../../constants.less";

#privacy {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: @cubosWhite;
    padding: 50px 0;
    text-align: justify;
    position: relative;

    h2 {
        margin-top: 50px;
    }

    .grouped {
        margin: 30px 0;

        p {
            margin: 0;
        }
    }
}
