@import "../../constants.less";

#contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: @cubosWhite;

    .contact-us {
        .card {
            padding: 0px;

            .contact-us-content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                hr {
                    border: 1px @cubosWhite solid;
                    height: 2px;
                    background-color: @cubosWhite;
                    width: 100px;
                    margin: 24px auto 24px 0;
                }

                h1,
                p {
                    margin: 4px 0;
                }
            }
        }
    }
}

@media screen and (max-width: 950px) {
    #contact {
        .contact-us {
            .card {
                img {
                    width: 100%;
                    object-fit: contain;
                }
            }

            .contact-us-content {
                margin-left: 20px;
            }
        }
    }
}
