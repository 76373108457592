@import "../../constants.less";

#cookie-wrapper {
    width: 100%;
    display: flex;
    height: fit-content;
    justify-content: center;
    position: fixed;
    bottom: 0;
    z-index: 1000;
    font-family: Rubik-Bold;

    .cookie-banner {
        max-width: 100%;
        width: fit-content;
        background-color: @cubosGrey-text;
        z-index: 999;
        padding: 16px 40px;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .cookie-content {
            display: flex;
            align-items: center;
            text-align: center;
            color: white;
        }

        .cookie-buttons {
            display: flex;
            justify-content: center;
            flex-direction: row-reverse;
            margin-top: 16px;

            button {
                padding: 8px 24px;
                background-color: @cubosBlue;
                margin: 0 24px;
                border-radius: 12px;
                border: none;
                color: white;
                font-size: large;
                font-family: Rubik-Bold;
                cursor: pointer;

                &.decline-btn {
                    background-color: @cubosPink;
                }
            }
        }
    }
}

@media screen and (max-width: @mobile-design) {
    #cookie-wrapper {
        .cookie-banner {
            width: 100%;
            padding: 16px;
            display: flex;
            flex-direction: column;
            border-radius: 0;

            .cookie-buttons {
                display: flex;
                justify-content: center;
                text-align: left;

                button {
                    margin: 10px 15px;
                }
            }
        }
    }
}
