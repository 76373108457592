@import "../../constants.less";

#landing {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: @cubosWhite;

    #welcome-message,
    #landing-goal {
        position: relative;
        margin: 30px 0;
        padding: 40px 0;
        width: 100vw;
        min-height: 400px;
        display: flex;
        justify-content: center;
        text-align: center;
        background-color: @cubosLightGrey;
        color: @cubosGrey-text;

        p {
            font-size: 30px;
            font-family: Rubik-Bold;
        }
    }

    #landing-goal {
        p {
            font-size: 18px !important;
        }
    }

    .card-content {
        display: flex;
        flex-direction: column;

        p {
            text-align: justify;
        }
    }

    img {
        &.desktop {
            display: block;
        }
        &.mobile {
            display: none;
        }
    }

    #categories {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 20px 0;

        .category-img {
            box-sizing: border-box;
            padding: 15px;
            width: 50%;

            img {
                width: 100%;
                object-fit: contain;
            }
        }
    }
}

@media screen and (max-width: @mobile-design) {
    #landing {
        #welcome-message {
            p {
                padding: 0 20px;
                font-size: 24px;
            }
        }

        #landing-goal {
            p {
                padding: 0 20px;
            }
        }

        img {
            &.desktop {
                display: none;
            }
            &.mobile {
                display: block;
            }
        }

        #categories {
            .category-img {
                margin: 20px 0;
                padding: 0;
                width: 100%;
            }
        }
    }
}
