@import "../../constants.less";

footer#footer {
    background-color: @cubosDarkGrey;
    padding: 16px 40px;

    .footer-container {
        display: flex;
        justify-content: space-between;

        hr {
            display: none;
        }

        h1,
        p {
            color: @cubosWhite;
            margin: 10px 0;
        }

        .footer-info,
        .footer-links {
            display: flex;
            padding: 20px;
            flex-direction: column;
            justify-content: space-between;
        }

        .footer-info {
            img {
                width: 330px;
                max-width: 90vw;
                object-fit: contain;
            }
        }

        .footer-links > div {
            display: flex;
            justify-content: flex-end;

            > a {
                margin: 0 20px;
                text-decoration: none;

                &.privacy-link {
                    color: @cubosWhite;
                }

                &.contact-link {
                    color: @cubosWhite;
                }

                > img {
                    width: 50px;
                    height: 50px;
                    object-fit: contain;
                }
            }
        }
    }
}

@media screen and (max-width: @mobile-design) {
    footer#footer {
        padding: 16px;

        .footer-container {
            flex-direction: column-reverse;

            hr {
                display: block;
                width: 100%;
            }

            .footer-links {
                padding: 0;
                flex-direction: column-reverse;

                > div {
                    margin: 20px 10px;

                    > a {
                        margin: 0px;

                        > img {
                            width: 46px;
                            height: 46px;
                        }
                    }

                    &:first-child {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;

                        a {
                            margin: 20px 5px;
                        }
                    }

                    &:last-child {
                        display: flex;
                        justify-content: space-between;
                    }
                }
            }

            .footer-info {
                justify-content: center;
                align-items: center;
                text-align: center;
            }
        }
    }
}
