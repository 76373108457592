@import "../../constants.less";

.cubos-hero {
    position: relative;

    .cubos-overlay {
        position: absolute;
        inset: 0;
        z-index: 10;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 16px;
    }

    video {
        width: 100%;
        object-fit: contain;
        border-radius: 16px;
        margin-bottom: -10px;
    }
}

.slide {
    &:nth-child(1) {
        .cubos-overlay {
            background-color: rgba(0, 0, 0, 0.25);
        }
    }
}

@media screen and (max-width: @mobile-design) {
    .cubos-hero {
        width: 100%;
        height: 75vh !important;
        overflow: hidden;

        video {
            width: 100%;
            height: 100%;
            object-fit: cover;

            &.left {
                object-position: left;
            }
        }
    }
}
